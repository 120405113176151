import axios from "axios";
import { UsersService } from "../api/UsersService";
const userService = new UsersService();

export const AUTHENTICATED = "authenticated_user";
export const UNAUTHENTICATED = "unauthenticated_user";
export const AUTHENTICATION_ERROR = "authentication_error";
export const AUTHENTICATION_DATA = "authentication_data";

const initialState = {
  userData: {
    sName: "",
    fName: "",
    roleId: "",
    mainRole: ""
  },
  authenticated: false,
  isAdmin: false
};

export const signInActions = {
  signInAction: (model, history) => {
    return async dispatch => {
      try {
        const res = await userService.login(model);
        dispatch({ type: AUTHENTICATED });
        localStorage.setItem("user", res.data.token);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("user");
        history.push("/");
      } catch (error) {
        dispatch({
          type: AUTHENTICATION_ERROR,
          payload: "Неправильный логин или пароль"
        });
      }
    };
  },
  signOutAction: () => {
    return async dispatch => {
      localStorage.removeItem("user");
      axios.defaults.headers.common["Authorization"] = "";
      dispatch({ type: UNAUTHENTICATED });
    };
  },
  regInAction: (user, history) => {
    return async dispatch => {
      try {
        const res = await userService.register(user);
        dispatch({ type: AUTHENTICATED });
        localStorage.setItem("user", res.data.token);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("user");
        history.push("/");
      } catch (error) {
        dispatch({
          type: AUTHENTICATION_ERROR,
          payload: "Пользователь уже зарегистрирован"
        });
      }
    };
  },
  getUserData: () => {
    return async dispatch => {
      try {
        // setTimeout(()=>{
        //         let sideBar = document.getElementsByClassName("sidebar-nav");
        //         document.body.classList.add('sidebar-minimized');
        //         sideBar[0].classList.remove('ps');
        //         sideBar[0].classList.remove('scrollbar-container');
        //     }, 5000
        // );
        const res = await userService.getUserData();
        if (
          res.status === "Token is Expired" ||
          res.status === "Token is Invalid" ||
          res.status === "Authorization Token not found"
        ) {
          this.signOutAction();
          return;
        }
        dispatch({
          type: AUTHENTICATION_DATA,
          payload: res
        });
      } catch (error) {
        dispatch({
          type: UNAUTHENTICATED
        });
      }
    };
  }
};

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case AUTHENTICATED:
      return { ...state, authenticated: true };
    case UNAUTHENTICATED:
      return { ...state, authenticated: false, error: "", isAdmin: false };
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        error: action.payload,
        authenticated: false,
        isAdmin: false
      };
    case AUTHENTICATION_DATA:
      return {
        ...state,
        userData: action.payload,
        authenticated: true,
        isAdmin: action.payload.mainRole === "admin"
      };
    default:
      return state;
  }
};
