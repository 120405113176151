import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>
          <a href="http://mixnews.uz">mixnews</a> &copy;{" "}
          {new Date().getFullYear()} <FormattedMessage id="mixNews" />.
        </span>
        <span className="ml-auto">
          <FormattedMessage id="developed" />:{" "}
          <a href="http://mixnews.uz">
            <FormattedMessage id="developerFIO" />
          </a>
        </span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
