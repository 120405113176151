import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import PropTypes from "prop-types";

import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from "@coreui/react";
import logo from "../../assets/img/brand/logo.png";
import sygnet from "../../assets/img/brand/sygnet.ico";

import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { signInActions } from "../../store/actions/signInAction";
import { configActions } from "../../store/actions/configAction";

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultHeader extends Component {
  componentDidMount() {
    this.props.getUserData();
  }
  changeLang = lang => {
    this.props.changeLang(lang, this.props.config);
    /* eslint-disable-next-line*/
    location.reload();
  };
  render() {
    const lang = this.props.lang === "en" ? "us" : this.props.lang;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 89, height: 25, alt: "Logo" }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: "Logo" }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="d-md-down-none" navbar />
        <Nav className="ml-auto" navbar>
          <NavItem className="d-md-down-none">
            <NavLink href="/#/config">
              <i className="fa fa-gear" /> <FormattedMessage id="nav.config" />
            </NavLink>
          </NavItem>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <i className={`flag-icon flag-icon-${lang}`} />{" "}
              <i
                className={
                  this.props.authCheck ? "fa fa-user" : "icon-login icons"
                }
              />
              &nbsp;
              {this.props.authCheck ? (
                this.props.userData.name
              ) : (
                <FormattedMessage id="nav.enter" />
              )}
              &nbsp; <i className="fa fa-caret-down" />
            </DropdownToggle>
            <DropdownMenu right style={{ right: "auto" }}>
              <DropdownItem header tag="div" className="text-center">
                <strong>
                  <FormattedMessage id="user" />
                </strong>
              </DropdownItem>
              <DropdownItem>
                <Link className="text-center" to="/config">
                  <i className="fa fa-cog" />{" "}
                  <FormattedMessage id="nav.config" />
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link className="text-center" to="/register">
                  <i className="fa fa-user" />{" "}
                  <FormattedMessage id="nav.registration" />
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link className="text-center" to="/login">
                  <i className="fa fa-lock" />
                  {this.props.authCheck ? (
                    <FormattedMessage id="nav.exit" />
                  ) : (
                    <FormattedMessage id="nav.enter" />
                  )}
                </Link>
              </DropdownItem>
              <DropdownItem header tag="div" className="text-center">
                <strong>
                  <FormattedMessage id="languages" />
                </strong>
              </DropdownItem>
              <DropdownItem onClick={() => this.changeLang("ru")}>
                <i className="flag-icon flag-icon-ru" title="ru" id="ru" />{" "}
                Русский
                {lang === "ru" && <i className="fa fa-check green miniBadge" />}
              </DropdownItem>
              <DropdownItem onClick={() => this.changeLang("uz")}>
                <i className="flag-icon flag-icon-uz" title="uz" id="uz" />{" "}
                O`zbek tili
                {lang === "uz" && <i className="fa fa-check green miniBadge" />}
              </DropdownItem>
              <DropdownItem onClick={() => this.changeLang("en")}>
                <i className="flag-icon flag-icon-us" title="us" id="us" />{" "}
                English
                {lang === "us" && <i className="fa fa-check green miniBadge" />}
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
    authCheck: state.auth.authenticated
  };
}
function mapStateToPropsS(state) {
  return {
    lang: state.config.config.lang,
    config: state.config.config
  };
}
export default injectIntl(
  connect(
    mapStateToProps,
    dispatch => bindActionCreators(signInActions, dispatch)
  )(
    connect(
      mapStateToPropsS,
      dispatch => bindActionCreators(configActions, dispatch)
    )(DefaultHeader)
  )
);
