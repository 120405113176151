import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import "./App.css";
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
//primeng
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
// Import Main styles for this application
import "./scss/style.css";
import "./index.css";
// Containers
import { DefaultLayout } from "./containers";
import en from "./helpers/en";
import ru from "./helpers/ru";
import uz from "./helpers/uz";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { configActions } from "./store/actions/configAction";

class App extends Component {
  render() {
    let langMessages = { en, ru, uz };
    let lang = this.props.lang;
    //console.log(lang);
    return (
      <IntlProvider locale={lang} messages={langMessages[lang]}>
        <Router>
          <Switch>
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </Router>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.config.config.lang
  };
}

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators(configActions, dispatch)
)(App);
