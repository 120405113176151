export default {
  items: [
    {
      id: "nav.dashboard",
      name: "Все новости",
      url: "/",
      icon: "icon-speedometer"
    },
    {
      id: "nav.config",
      name: "Настройки",
      url: "/config",
      icon: "icon-settings"
    },
    {
      id: "nav.enter",
      name: "Вход",
      url: "/login",
      icon: "icon-login"
    },
    {
      id: "nav.registration",
      name: "Регистрация",
      url: "/register",
      icon: "icon-user"
    },
    {
      divider: true
    },
    {
      id: "nav.sites",
      title: true,
      name: "Сайты",
      class: "" // optional class names space delimited list for title item ex: "text-center"
    }
  ]
};
