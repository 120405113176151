export default {
  "nav.dashboard": "All news",
  "nav.config": "Settings",
  "nav.enter": "Login",
  "nav.exit": "Exit",
  "nav.registration": "Registration",
  "nav.sites": "Sites",
  "nav.siteNews": "Site news",

  siteConfigTitle: "Config sites (sort,filter)",
  mixNews: "Mix News",
  developed: "Developed by",
  developerFIO: "Anvar Utepov",
  enterYourAccount: "Log in with your account",
  password: "Password",
  repeatPassword: "Confirm password",
  user: "User",
  languages: "Languages",
  lostPassword: "Lost Password?",
  registration: "To register",
  createNewAccount: "Create new user",
  fio: "Full Name",
  sort: "Sort",
  up: "Move up",
  down: "Move down",
  hide: "hide",
  configByDefault: "Reset all settings",
  success: "Successfully !",
  successAdd: "Successfully added !",
  errorRSS: "Error retrieving data from RSS feed.",
  rssAdd: "Add RSS feed",
  add: "Add"
};
