export default {
  "nav.dashboard": "Все новости",
  "nav.config": "Настройки",
  "nav.enter": "Вход",
  "nav.exit": "Выход",
  "nav.registration": "Регистрация",
  "nav.sites": "Сайты",
  "nav.siteNews": "Новости сайта",

  siteConfigTitle: "Настройка сайтов (сортировка, фильтр)",
  mixNews: "Смешанные новости",
  developed: "Разработано",
  developerFIO: "Утепов Анвар",
  enterYourAccount: "Зайдите под своим аккаунтом",
  password: "Пароль",
  repeatPassword: "Повторите пароль",
  user: "Пользователь",
  languages: "Языки",
  lostPassword: "Забыли пароль?",
  registration: "Зарегистрироваться",
  createNewAccount: "Создание нового пользователя",
  fio: "Ф.И.О.",
  sort: "Сортировка",
  up: "Верх",
  down: "Вниз",
  hide: "Скрыть",
  configByDefault: "Сбросить все настройки",
  success: "Успешно !",
  successAdd: "Успешно добавлено !",
  errorRSS: "Ошибка получения данных с RSS канала.",
  rssAdd: "Добавить RSS канал",
  add: "Добавить"
};
