import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
// sidebar nav config
import navigation from "../../_nav";
import navigationAdmin from "../../_navAdmin";
// routes config
import routes from "../../routes";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import { connect } from "react-redux";
import { signInActions } from "../../store/actions/signInAction";
import { configActions } from "../../store/actions/configAction";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navConfig: navigation
    };
  }

  componentDidMount() {
    this.props.getConfigAction().then(() => {
      this.navChange();
    });
  }
  navChange() {
    let itemsNav = this.props.authCheck
      ? navigationAdmin.items
      : navigation.items;
    itemsNav = [...itemsNav, ...this.props.nav];
    this.setState({
      navConfig: {
        items: itemsNav
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.authCheck !== this.props.authCheck) {
      this.navChange();
    }
    if (prevProps.nav.length > 0 && prevProps.nav !== this.props.nav) {
      if (prevProps.nav.length !== this.props.nav.length) {
        this.navChange();
      }

      for (let i = 0; i < prevProps.nav.length; i++) {
        if (prevProps.nav[i] === undefined || this.props.nav[i] === undefined) {
          this.navChange();
          break;
        }
        if (prevProps.nav[i].name !== this.props.nav[i].name) {
          this.navChange();
          // console.log(prevProps.nav);
          // console.log(this.props.nav);
          break;
        }
      }
    }
  }
  translateLang = id => {
    return <FormattedMessage id={id} />;
  };
  render() {
    let navFormatted = {
      items: this.state.navConfig.items.map(item => {
        if (item.id !== undefined) {
          item.name = this.translateLang(item.id);
        }
        return item;
      })
    };
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navFormatted} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            {/* <FormattedMessage id="nav.config" /> */}
            {/* <pre>{JSON.stringify(navConfig.items, null, 2)}</pre> */}
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authCheck: state.auth.isAdmin
  };
}
function mapStateToPropsS(state) {
  return {
    nav: state.config.nav
  };
}
export default connect(
  mapStateToProps,
  dispatch => bindActionCreators(signInActions, dispatch)
)(
  connect(
    mapStateToPropsS,
    dispatch => bindActionCreators(configActions, dispatch)
  )(DefaultLayout)
);
