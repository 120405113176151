import axios from "axios";

const URLMain = "/api/news";
//const URLAuth = '/api';

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("user");

export class NewsService {
  async getNewsList(filterObj) {
    const res = await axios.post(`${URLMain}/getNewsList`, filterObj);
    return res.data;
  }
  async getNewsById(Id) {
    const res = await axios.post(`${URLMain}/getNewsById?Id=` + Id);
    return res.data;
  }

  async getConfigs() {
    const res = await axios.post(`${URLMain}/getConfigs`);
    return res.data;
  }
  async getNews(model) {
    const res = await axios.post(`${URLMain}/getNews`, model);
    return res.data;
  }
  async getNewsBySiteId(model) {
    const res = await axios.post(`${URLMain}/getNewsBySiteId`, model);
    return res.data;
  }
  async getErrorsList() {
    const res = await axios.post(`${URLMain}/getErrorsList`);
    return res.data;
  }
  async setClearErrorsList() {
    const res = await axios.post(`${URLMain}/setClearErrorsList`);
    return res.data;
  }

  async setDeleteNewsByDate(model) {
    const res = await axios.post(`${URLMain}/setDeleteNewsByDate`, {
      dateFrom: model
    });
    return res.data;
  }
  async setNewsToBase() {
    const res = await axios.get(`${URLMain}/setNewsToBase`);
    return res.data;
  }
}
