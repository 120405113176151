import { NewsService } from "../api/NewsService";

export const GET_CONFIG = "GET_CONFIG";
export const SET_CONFIG = "SET_CONFIG";
export const DELETE_CONFIG = "DELETE_CONFIG";
export const ERROR_CONFIG = "ERROR_CONFIG";

export const SET_SORT_SITES_HIDE = "SET_SORT_SITES_HIDE";
export const SET_SORT_SITES_SWAP = "SET_SORT_SITES_SWAP";

export const SET_NEXT_NEWS = "SET_NEXT_NEWS";
export const SET_PREV_NEWS = "SET_PREV_NEWS";
export const SET_FIRST_NEWS = "SET_FIRST_NEWS";

export const SET_LANG = "SET_LANG";

const initialState = {
  config: {
    lang: "ru",
    siteList: [],
    categories: []
  },
  nav: []
};
const newsService = new NewsService();

export const configActions = {
  getConfigAction: () => {
    return async dispatch => {
      try {
        let res = localStorage.getItem("config-news");
        if (res === null || res === undefined) {
          res = await newsService.getConfigs();
          localStorage.setItem("config-news", JSON.stringify(res));
        } else {
          res = JSON.parse(res);
        }
        res.siteList = sortArray(res.siteList);
        dispatch({ type: GET_CONFIG, payload: res });
      } catch (error) {
        //console.log(error);
        alert("Ошбика получения данных!");
        dispatch({
          type: ERROR_CONFIG,
          payload: "Ошбика получения данных!"
        });
      }
    };
  },
  setConfigAction: model => {
    return async dispatch => {
      localStorage.setItem("config-news", JSON.stringify(model));
      dispatch({ type: SET_CONFIG, payload: model });
    };
  },
  deleteConfigAction: () => {
    return async dispatch => {
      localStorage.removeItem("config-news");
      dispatch({ type: DELETE_CONFIG });
    };
  },
  setSortSitesSwap: (indexFirst, indexSecond, config) => {
    return async dispatch => {
      let newsSiteList = config.siteList;
      if (indexFirst < indexSecond) {
        for (let i = indexFirst; i < indexSecond; i++) {
          let tempSortItem = newsSiteList[i + 1].siteOrder;
          newsSiteList[i + 1].siteOrder = newsSiteList[indexFirst].siteOrder;
          newsSiteList[indexFirst].siteOrder = tempSortItem;
        }
      }
      if (indexFirst > indexSecond) {
        for (let i = indexFirst - 1; i >= indexSecond; i--) {
          let tempSortItem = newsSiteList[i].siteOrder;
          newsSiteList[i].siteOrder = newsSiteList[indexFirst].siteOrder;
          newsSiteList[indexFirst].siteOrder = tempSortItem;
        }
      }
      config.siteList = sortArray(newsSiteList);
      dispatch({ type: SET_SORT_SITES_SWAP, payload: config });
      dispatch({ type: SET_CONFIG, payload: config });
      localStorage.setItem("config-news", JSON.stringify(config));
    };
  },
  setSortSitesHide: (id, config) => {
    return async dispatch => {
      config.siteList = config.siteList.map(item => {
        item.active =
          item.id === id ? (item.active === 1 ? 0 : 1) : item.active;
        return item;
      });
      config.siteList = sortArray(config.siteList);
      dispatch({ type: SET_SORT_SITES_HIDE, payload: config });
      dispatch({ type: SET_CONFIG, payload: config });
      localStorage.setItem("config-news", JSON.stringify(config));
    };
  },
  setNextNews: (id, config) => {
    return async dispatch => {
      config.siteList = config.siteList.map(item => {
        item.newsSkip = item.id === id ? item.newsSkip + 4 : item.newsSkip;
        return item;
      });
      dispatch({ type: SET_NEXT_NEWS, payload: config });
    };
  },
  setPrevNews: (id, config) => {
    return async dispatch => {
      config.siteList = config.siteList.map(item => {
        item.newsSkip = item.id === id ? item.newsSkip - 4 : item.newsSkip;
        return item;
      });
      dispatch({ type: SET_PREV_NEWS, payload: config });
    };
  },
  setFirstNews: (id, config) => {
    return async dispatch => {
      config.siteList = config.siteList.map(item => {
        item.newsSkip = item.id === id ? 0 : item.newsSkip;
        return item;
      });
      dispatch({ type: SET_FIRST_NEWS, payload: config });
    };
  },
  changeLang: (lang, config) => {
    return async dispatch => {
      config.lang = lang;
      dispatch({ type: SET_LANG, payload: lang });
      dispatch({ type: SET_CONFIG, payload: config });
      localStorage.setItem("config-news", JSON.stringify(config));
    };
  }
};
function sortArray(oArray) {
  return oArray.sort((a, b) => {
    if (a.siteOrder > b.siteOrder) {
      return 1;
    }
    if (a.siteOrder < b.siteOrder) {
      return -1;
    }
    return 0;
  });
}
function createNavigation(siteList) {
  let nav = siteList
    .sort((a, b) => {
      if (a.siteOrder > b.siteOrder) {
        return 1;
      }
      if (a.siteOrder < b.siteOrder) {
        return -1;
      }
      return 0;
    })
    .filter(item => item.active === 1)
    .map(item => {
      return {
        name: `${item.siteName}`,
        url: `/news_detail/${item.id}`,
        icon: "icon-globe"
      };
    });
  return nav;
}

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        config: action.payload,
        nav: createNavigation(action.payload.siteList)
      };
    case GET_CONFIG:
      return {
        ...state,
        config: action.payload,
        nav: createNavigation(action.payload.siteList)
      };
    case SET_SORT_SITES_SWAP:
      return {
        ...state,
        config: action.payload
      };
    case SET_SORT_SITES_HIDE:
      return {
        ...state,
        config: action.payload
      };
    case SET_NEXT_NEWS:
      return {
        ...state,
        config: action.payload
      };
    case SET_PREV_NEWS:
      return {
        ...state,
        config: action.payload
      };
    case SET_FIRST_NEWS:
      return {
        ...state,
        config: action.payload
      };
    case SET_LANG:
      return {
        ...state,
        config: {
          ...state.config,
          lang: action.payload
        }
      };
    case ERROR_CONFIG:
      return {
        ...state,
        error: action.payload
      };
    case DELETE_CONFIG:
      return state;
    default:
      return state;
  }
};
