export default {
  "nav.dashboard": "Barcha yangiliklar",
  "nav.config": "Sozlash",
  "nav.enter": "Tizimga kirish",
  "nav.exit": "Tizimdan chiqish",
  "nav.registration": "Ro'yxatdan o`tish",
  "nav.sites": "Saytlar",
  "nav.siteNews": "Saytni yangilliklari",

  siteConfigTitle: "Sozlash (tartiblash,filtr)",
  mixNews: "Qo'shma xabarlar",
  developed: "Ishlab chiqilgan",
  developerFIO: "Anvar Utepov",
  enterYourAccount: "Uchetkangiz bilan kiring",
  password: "Parol",
  repeatPassword: "Parolni qayta kiriting",
  user: "Foydalanuvchi",
  languages: "Tillar",
  lostPassword: "Parolni tiklash?",
  registration: "Ro'yxatdan o`tish uchun",
  createNewAccount: "Yangi foydalanuvchi yaratish",
  fio: "To'liq ism, familiya",
  sort: "Tartiblash",
  up: "Yuqoriga",
  down: "Pastga",
  hide: "Yoshirish",
  configByDefault: "Barcha sozlamalarni tiklash",
  success: "Muvaffaqiyatli !",
  successAdd: "Muvaffaqiyatli qo'shildi !",
  errorRSS: "RSS ma'lumotidan ma'lumotlarni olishda xatolik yuz berdi.",
  rssAdd: "RSS kanal qo'shish",
  add: "Qo'shish"
};
