import axios from 'axios';

const URLAdmin = '/api/admin';
const URLAuth = '/api';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('user');        

export class UsersService {
    getUsers(filterObj) {
        return axios.post(`${URLAdmin}/getUsersList`, filterObj)
            .then(res => res.data);
    }   
    getUserById(userId) {
        return axios.post(`${URLAdmin}/getUserById?userId=`+userId)
            .then(res => res.data);
    }   
    setUser(model) {
        return axios.post(`${URLAdmin}/setUser`,model)
            .then(res => res.data);
    }   
    resetPasswordUser(model) {
        return axios.post(`${URLAuth}/resetPasswordUser?userEmail=`+ model)
            .then(res => res.data);
    }
    getUserData() {
        return axios.post(`${URLAuth}/getUserData`)
            .then(res => res.data);
    }   
    login(model) {
        return axios.post(`${URLAuth}/login`, model);
    }    
    register(model) {
        return axios.post(`${URLAuth}/register`, model);
    }    
}

