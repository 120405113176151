export default {
  items: [
    {
      id: "nav.dashboard",
      name: "Все новости",
      url: "/",
      icon: "icon-speedometer"
      // badge: {
      //   variant: "info",
      //   text: "NEW"
      // }
    },
    {
      name: "Настройки",
      url: "/config",
      icon: "icon-settings"
    },
    {
      divider: true
    },
    {
      title: true,
      name: "Администрирование",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "" // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Пользователи",
      url: "/admin/users",
      icon: "icon-people"
    },
    {
      name: "Категории",
      url: "/admin/sp_categories_page",
      icon: "icon-layers"
    },
    {
      name: "Сайты",
      url: "/admin/sites_page",
      icon: "icon-globe"
    },
    {
      name: "Новости",
      url: "/admin/news_page",
      icon: "icon-eye"
    },
    {
      divider: true
    },
    {
      title: true,
      name: "Дополнительно"
    },
    {
      name: "Страницы",
      url: "/pages",
      icon: "icon-folder",
      children: [
        {
          name: "Вход",
          url: "/login",
          icon: "icon-login"
        },
        {
          name: "Регистрация",
          url: "/register",
          icon: "icon-user"
        },
        {
          name: "Error 404",
          url: "/404",
          icon: "icon-fire"
        },
        {
          name: "Error 500",
          url: "/500",
          icon: "icon-fire"
        }
      ]
    },
    {
      divider: true
    },
    {
      title: true,
      name: "Сайты",
      class: "" // optional class names space delimited list for title item ex: "text-center"
    }
  ]
};
