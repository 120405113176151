import React from "react";
import Loadable from "react-loadable";
//import { ProgressSpinner } from "primereact/progressspinner";
import { FormattedMessage } from "react-intl";
import CSpinner from "./helpers/CSpinner";
//import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return <CSpinner />;
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading
});
const Register = Loadable({
  loader: () => import("./views/Pages/Register/Register"),
  loading: Loading
});
const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404/Page404"),
  loading: Loading
});
const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500/Page500"),
  loading: Loading
});
const Login = Loadable({
  loader: () => import("./views/Pages/Login/Login"),
  loading: Loading
});

const Users = Loadable({
  loader: () => import("./views/Users/UsersPage"),
  loading: Loading
});
const SPCategoriesPage = Loadable({
  loader: () => import("./views/SPCategory/SPCategoriesPage"),
  loading: Loading
});
const SPSitesPage = Loadable({
  loader: () => import("./views/Sites/SPSitesPage"),
  loading: Loading
});
const NewsPage = Loadable({
  loader: () => import("./views/News/NewsPage"),
  loading: Loading
});
const NewsDetail = Loadable({
  loader: () => import("./views/NewsDetail/NewsDetail"),
  loading: Loading
});
const Config = Loadable({
  loader: () => import("./views/Config/Config"),
  loading: Loading
});
const Ru = Loadable({
  loader: () => import("./views/Pages/Lang/Ru"),
  loading: Loading
});
const Test = Loadable({
  loader: () => import("./views/Pages/Test/Test"),
  loading: Loading
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const routes = [
  {
    path: "/",
    exact: true,
    name: <FormattedMessage id="nav.dashboard" />,
    component: Dashboard
  },
  {
    path: "/register",
    name: <FormattedMessage id="nav.registration" />,
    component: Register
  },
  { path: "/404", exact: true, name: "404", component: Page404 },
  { path: "/500", exact: true, name: "500", component: Page500 },
  {
    path: "/login",
    exact: true,
    name: <FormattedMessage id="nav.enter" />,
    component: Login
  },
  { path: "/admin/users", exact: true, name: "Пользователи", component: Users },
  {
    path: "/admin/sp_categories_page",
    exact: true,
    name: "Категории",
    component: SPCategoriesPage
  },
  {
    path: "/admin/sites_page",
    exact: true,
    name: "Сайты",
    component: SPSitesPage
  },
  {
    path: "/admin/news_page",
    exact: true,
    name: "Новости",
    component: NewsPage
  },
  {
    path: "/news_detail/:id",
    exact: true,
    name: <FormattedMessage id="nav.siteNews" />,
    component: NewsDetail
  },
  {
    path: "/config",
    exact: true,
    name: <FormattedMessage id="nav.config" />,
    component: Config
  },
  {
    path: "/ru",
    exact: true,
    name: "Ru",
    component: Ru
  },
  {
    path: "/test",
    exact: true,
    name: "test",
    component: Test
  },
  { name: "Error", component: Page404 }
];

export default routes;
